import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache} from '@apollo/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { Dashboard, Home } from './sections';
import "./styles/index.css";
import logo from './assets/img/logo.png';
import { Button, Navbar, Nav } from 'react-bootstrap';
import { AuthCheck, FirebaseAppProvider, useAuth } from 'reactfire';
import firebase from 'firebase/app';
import { firebaseConfig } from './firebaseConfig';
import 'firebase/auth';

const client = new ApolloClient({
  uri: 'http://localhost:4000',
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Router>
    <Navbar bg='dark' variant='dark'>
      <Navbar.Brand>
        <Link to='/'>GDS Team Console</Link>
      </Navbar.Brand>

      <Nav className="move-nav" activeKey='/'>

        <Nav.Item>
          <Nav.Link>
            <Link to='/'> Home </Link>
          </Nav.Link>
        </Nav.Item>

        <AuthCheck fallback={
            <Nav.Item>
            <Nav.Link>
            <Link to="/">Login here</Link>
            </Nav.Link>
          </Nav.Item>
          }>

        <Nav.Item>
          <Nav.Link>
            <Link to='/dashboard'> GDS Team Dashboard </Link>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
            <Nav.Link>
            <Link to="/">Logout</Link>
            </Nav.Link>
          </Nav.Item>
        </AuthCheck>

      </Nav>
    </Navbar>

    <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
    </FirebaseAppProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
