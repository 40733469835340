import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation} from '@apollo/react-hooks';
import "./styles/Dashboard.css";
import algoliasearch from 'algoliasearch';
import { Button, Table, Card, Col,Row,Container, ListGroup,Jumbotron,Accordion } from 'react-bootstrap';
import { InstantSearch, SearchBox, Hits, Highlight, RefinementList, Pagination} from 'react-instantsearch-dom';

const searchClient = algoliasearch('DYOENB0F7P', 'e346b24f0b9a4ff571f104b5d349fd97');



interface Client {
    id?: String;
    Name: String;
    TaxYear: String;
    UploadDate: String;
    downloadURL: String;
    path: String;
}

const CLIENTS = gql`
    query getClient{
  clients{
    id
    Name
    TaxYear
    UploadDate
    downloadURL
    path
  }
}
`
const Hit = ({hit}:any) => 
            <ListGroup horizontal>
                <ListGroup.Item variant="dark">{hit.Name}</ListGroup.Item>
                <ListGroup.Item variant="dark">{hit.TaxYear}</ListGroup.Item>
                <ListGroup.Item variant="dark">{hit.UploadDate}</ListGroup.Item>
                <ListGroup.Item variant="dark">
                    <Button variant="outline-primary" href={hit.downloadURL}>
                        Review Doc
                    </Button>
                </ListGroup.Item>
            </ListGroup>

const SideBar = (
<Accordion defaultActiveKey="0">
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="0">
      Filter By Name
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="0">
    <Card.Body>
        <RefinementList attribute="Name" searchable={true}/>
    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
  <Card.Header>
    <Accordion.Toggle as={Button} variant="link" eventKey="1">
      Filter By Tax Year
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="1">
  <RefinementList attribute="TaxYear" searchable={true}/>
  </Accordion.Collapse>
</Card>
</Accordion>
)





export const Dashboard = () => {
    const { data, loading, error, refetch } = useQuery(CLIENTS);

    const search = (
    <InstantSearch searchClient = {searchClient} indexName = "GDS Client Search">
        <header className="header">
        <h2>GDS Client Dashboard</h2>
            <SearchBox translations={{ placeholder:'Start Searching For Client Docs...'}} />
        </header>
        <Row>
        <div className="center-content">
            <Col sm={12}>
                <Hits hitComponent={Hit} />
            </Col>
        </div>
        </Row>
        <Pagination totalPages = {10}></Pagination>
    </InstantSearch>
    )
    


    return (
        <div>
                {search}
        </div>
    )
}