import React from 'react';
import firebase from 'firebase/app';
import { AuthCheck, FirebaseAppProvider, useAuth } from 'reactfire';
import { Jumbotron, Button, Container, Card, Col,Row } from 'react-bootstrap';
import 'firebase/auth';

export const Home = () => {

    const auth = useAuth();

    const signIn = async () => {
        await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    const signOut = async () => {
        await auth.signOut();
    }




    return (
        <Container>
            <Jumbotron>
                <h1>Login/Logout Here</h1>
            <AuthCheck
                fallback={
                    <Button onClick={signIn}>
                        Sign In
                    </Button>
                }
                >
                    <Button onClick={signOut}>
                        SignOut
                    </Button>
                </AuthCheck>
            </Jumbotron>
        </Container>
    )
}